<template>
  <div class="container">
    <van-tabs
      v-model:active="active"
      @click-tab="handleClickTab"
      @change="loadGiftBagList"
      background="var(--app-bg-color)"
      line-height="0"
      :ellipsis="false"
      sticky
      animated
      swipeable
    >
      <van-tab v-for="(game, index) in games" :key="index">
        <template #title>
          <img :class="{ 'animate__animated animate__wobble': active == index }" :src="game.logo" />
        </template>
        <div class="content">
          <div class="title">{{ game.name }}</div>
          <div class="no-list" v-if="isEmpty">暂无数据</div>
          <!-- <div class="auto-receive"><van-button block round color="var(--app-button-red-bg-color)">一键领取</van-button></div> -->
          <div class="list" v-else>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <div class="card" v-for="(giftBag, bagIndex) in list" :key="bagIndex">
                <div class="gift-title">
                  <van-icon name="point-gift-o" size="22"/>
                  <div class="gift-name">{{ giftBag.name }}</div>
                </div>
                <div class="gift-items">
                  <div class="item-box" v-for="(item, itemId) in giftBag.items" :key="itemId">
                    <div class="item">
                      <div class="item-title">{{ item.name }}</div>
                      <div class="item-num">x{{ item.amount }}</div>
                    </div>
                  </div>
                </div>
                <div class="gift-introduce" :id="'introduce' + giftBag.id" @click="changeIntroduce(bagIndex, 'introduce' + giftBag.id)" :style="giftBag.style">
                  {{ giftBag.description }}
                </div>
                <div class="gift-action">
                  <div class="other-info">
                    <van-count-down v-show="giftBag.hasReceived && giftBag.nextCountDown > 0" :time="giftBag.nextCountDown * 1000">
                      <template #default="timeData">
                        <span v-show="timeData.days">{{ timeData.days }}天</span>{{ timeData.hours }}时{{ timeData.minutes }}分{{
                          timeData.seconds
                        }}秒后可领取</template
                      >
                    </van-count-down>
                  </div>
                  <div class="actions">
                    <van-button
                      v-if="giftBag.mode == 1"
                      @click="handleReceive(bagIndex, giftBag, game.name)"
                      round
                      :color="giftBag.hasReceived ? 'var(--app-button-bg-color)' : 'var(--app-button-red-bg-color)'"
                      >{{ giftBag.hasReceived ? '查看CDKEY' : '领取' }}</van-button
                    >
                    <van-button
                      v-else
                      @click="handleReceive(bagIndex, giftBag, game.name)"
                      :disabled="giftBag.hasReceived"
                      round
                      color="var(--app-button-red-bg-color)"
                      >{{ giftBag.hasReceived ? '已领取' : '领取' }}</van-button
                    >
                  </div>
                </div>
              </div>
            </van-pull-refresh>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <van-dialog v-model:show="showReservationDialog" theme="round-button">
      <div class="van-dialog__message" v-if="receiveInfo.cdkey">
        领取《{{ receiveInfo.gameName }}》{{ receiveInfo.bagName }}成功，请登录游戏兑换CDKEY，CDKEY为：
        <h3 style="color: var(--app-active-color)">{{ receiveInfo.cdkey }}</h3>
      </div>
      <div class="van-dialog__message" v-else>领取《{{ receiveInfo.gameName }}》{{ receiveInfo.bagName }}成功，请登录游戏领取。</div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: 'WelfareCenter',
}
</script>
<script setup>
import { Tab, Tabs, Button, List, PullRefresh, Image, Dialog, CountDown,Icon } from 'vant'
import { ref, nextTick, onMounted, reactive } from 'vue'
import { giftBagList, receiveGiftBag } from '@/api/welfareCenter.js'
import { gameList } from '@/api/gameData.js'
import { bindSteamConfirm } from '@/utils/user'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const VanTab = Tab
const VanTabs = Tabs
const VanButton = Button
const VanList = List
const VanPullRefresh = PullRefresh
const VanImage = Image
const VanIcon = Icon
const VanCountDown = CountDown
const VanDialog = Dialog.Component
const store = useStore()
const router = useRouter()

const active = ref(0)
onMounted(() => {
  initData()
})

const games = ref([])
const list = ref([])

const initData = () => {
  store.dispatch('user/getInfo')
  //获取游戏列表
  gameList().then(res => {
    res.data.games = res.data.games.filter(item => item.show_welfare === 1);
    games.value = res.data.games
    loadGiftBagList()
  })
}

const isEmpty = ref(false)

const handleClickTab = () => {
  isEmpty.value = false
}
const loadGiftBagList = () => {
  list.value.length = 0
  giftBagList({ project: games.value[active.value].project }).then(res => {
    isEmpty.value = !res.data.list.length
    res.data.list.forEach(item => {
      item.style = { height: '', orgHeight: '', 'white-space': 'nowrap' }
      list.value.push(item)
    })
    refreshing.value = false
  })
}

const refreshing = ref(false)
const onRefresh = () => {
  loadGiftBagList()
}

const changeIntroduce = (index, id) => {
  list.value[index].style['white-space'] = list.value[index].style['white-space'] == 'nowrap' ? 'normal' : 'nowrap'
  const node = document.querySelector('#' + id)
  if (!list.value[index].style.orgHeight) list.value[index].style.orgHeight = node.clientHeight + 'px'
  if (!list.value[index].style.height) list.value[index].style.height = node.clientHeight + 'px'
  nextTick(() => {
    list.value[index].style.height = list.value[index].style['white-space'] == 'nowrap' ? list.value[index].style.orgHeight : node.scrollHeight + 'px'
    // console.log(list.value[index].style)
  })
}

const receiveInfo = ref({})
const showReservationDialog = ref(false)
const handleReceive = (bagIndex, giftBag, gameName) => {
  //判断是否绑定steamAccount
  if (!store.state.user.info.player_id) {
    bindSteamConfirm('领取礼包')
    return false
  }

  receiveGiftBag({ id: giftBag.id }).then(res => {
    console.log(gameName)
    receiveInfo.value = res.data.receive
    receiveInfo.value.gameName = gameName
    receiveInfo.value.bagName = giftBag.name
    list.value[bagIndex].hasReceived = true
    showReservationDialog.value = true
  })
}

const getFormat = time => {
  let hour = Math.ceil(time / 3600)
  if (hour >= 24) {
    return 'DD天HH时mm分ss秒后可领取'
  } else {
    return 'HH时mm分ss秒后可领取'
  }
}
</script>
<style lang="scss" scoped>
.container {
  color: #fff;
  ::v-deep .van-tabs__wrap {
    height: auto;
    .van-tabs__nav--line {
      padding-top: 15px;
      padding-bottom: 8px;
      // border: 0 0 10px 10px;
    }
    .van-tabs__line {
      display: none;
    }
  }

  .van-tab {
    img {
      border-radius: 5px;
      width: 42px;
      height: 42px;
    }
  }

  .van-tab--active {
    img {
      width: 55px;
      height: 55px;

      // box-shadow: 0 0 8px 3px #d35400;
      box-shadow: 0 0 8px 3px #337de0;
    }
  }

  .content {
    margin-top: 5px;
    min-height: 80vh;
    .no-list {
      color: #969799;
      font-size: 14px;
      padding-top: 30px;
      box-sizing: border-box;
      height: calc(100vh - 80px - constant(safe-area-inset-bottom));
      height: calc(100vh - 80px - env(safe-area-inset-bottom));
      text-align: center;
    }
    .title {
      font-size: 16px;
      text-align: center;
      color: #fff;
      &::before {
        content: '';
        background: url('/images/common/name-left-icon.png') no-repeat;
        background-size: 100%;
        width: 16px;
        height: 13px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
      &::after {
        content: '';
        background: url('/images/common/name-right-icon.png') no-repeat;
        background-size: contain;
        width: 16px;
        height: 13px;
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
    .auto-receive {
      padding: 15px;
    }
    .list {
      padding: 0 15px;
      padding-bottom: 10px;
      .card {
        background: var(--app-foot-color);
        padding: 10px;
        margin-top: 10px;
        border-radius: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .gift-title {
          display: flex;
          align-items: center;
          .gift-name {
            padding-left: 5px;
            color: var(--app-main-title-color);
            font-size: 15px;
            font-weight: 400;
          }
        }
        .gift-items {
          display: flex;
          margin-bottom: 5px;
          flex-wrap: wrap;
          .item-box {
            margin-top: 5px;
            flex-shrink: 0;
            width: 50%;
            padding-right: 10px;
            box-sizing: border-box;
            &::nth-child(2n) {
              padding-right: 0;
            }
            .item {
              padding: 6px;
              border-radius: 5px;
              background: var(--app-tag-bg-color);
              .item-title {
                color: var(--app-text-color);
                font-weight: 500;
                font-size: 13px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .item-num {
                font-size: 12px;
                color: var(--app-tag-text-color);
                font-weight: 500;
              }
            }
          }
        }
        .gift-introduce {
          word-break: break-all;
          font-size: 12px;
          color: var(--app-title-color);
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: height 0.3s ease-in-out;
        }
        .gift-action {
          display: flex;
          border-top: 1px solid var(--app-line-color);
          justify-content: space-between;
          padding-top: 10px;
          margin-top: 5px;
          .other-info {
            .van-count-down {
              font-size: 12px;
              font-weight: normal;
              color: var(--app-tag-text-color);
              background: var(--app-tag-bg-color);
              border-radius: 5px;
              padding: 5px;
              text-align: center;
            }
          }
          .actions {
            display: flex;
            flex-direction: row-reverse;
          }
          .van-button--normal {
            padding: 0 25px;
          }
          .van-button {
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
</style>
