import http from '@/utils/request'

/**
 * 礼包列表
 * @returns
 */
export const giftBagList = (params = {}) => {
  return http.get('/welfare-center/gift-bag-list', params)
}

/**
 * 领取礼包
 * @returns
 */
export const receiveGiftBag = (data = {}) => {
  return http.post('/welfare-center/receive', data)
}
